<template>
  <v-app>
    <v-container
      id="agendavac"
      class="justify-center mb-6"
    >
      <v-row class="py-6 px-2 text-center justify-center">
        <v-col
          cols="4"
          sm="3"
          md="2"
          lg="2"
        >
          <v-img
            src="@/assets/pmmg-logo.png"
            class="img-header"
          />
        </v-col>
        <v-col
          cols="8"
          sm="9"
          md="10"
          lg="10"
        >
          <div class="py-5 text-header text-left">
            <p class="font-weight-black">
              AGENDAMENTO
            </p>
            <p>
              DE VACINAÇÃO - COVID-19
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-col>
          <base-material-card
            color="#2c3e50"
            class="justify-center text-center"
          >
            <template v-slot:heading>
              <v-tabs
                v-model="tabs"
                background-color="transparent"
                slider-color="white"
                centered
                show-arrows
                touchless
              >
                <v-tab
                  href="#welcome"
                  class="mr-3"
                >
                  Bem Vindo
                </v-tab>
              </v-tabs>
            </template>
            <v-tabs-items
              :value="tabs"
              touchless
            >
              <v-tab-item
                value="welcome"
                class="justify-center mt-3"
              >
                <v-row>
                  <v-col
                    cols="12"
                  >
                    <div class="mb-4 text-h3">
                      AVISOS IMPORTANTES
                    </div>
                    <div
                      v-for="message in parseNotifications('cabecalho')"
                      :key="message.mensagem"
                      class="text-center mb-4 text-h4 text-uppercase"
                    >
                      <v-icon medium>
                        mdi-check-outline
                      </v-icon>
                      &nbsp;
                      <span
                        @click="message.acao !== null && notificationActions(message.acao.titulo, message.acao.mensagem)"
                        v-html="message.mensagem"
                      />
                    </div>
                  </v-col>
                </v-row>
                <v-row
                  class="justify-center mt-3"
                >
                  <v-col
                    v-if="images[0]"
                    cols="12"
                    md="6"
                  >
                    <v-img
                      :src="images[0].mensagem"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    style="display: flex; flex-wrap: inherit; justify-content: space-arround"
                  >
                    <v-col
                      v-for="message in allMessages()"
                      :key="message.mensagem"
                      cols="12"
                      md="6"
                      sm="6"
                      class="flex-grow-0 ma-n1"
                    >
                      <v-card
                        dark
                        shaped
                        class="mt-n1 mb-1 d-flex flex-column"
                        :color="cards[message.tipo]"
                        min-height="255px"
                      >
                        <v-card-title
                          class=" mb-8 pt-6 text-h4 justify-center text-uppercase"
                        >
                          {{ message.titulo || message.tipo }}
                        </v-card-title>
                        <v-card-subtitle
                          class="text-left"
                        >
                          <p v-html="message.mensagem" />
                        </v-card-subtitle>
                        <v-spacer />
                        <v-card-actions
                          v-if="message.acao !== null"
                          class="pt-0"
                        >
                          <v-btn
                            text
                            @click="message.acao !== null && notificationActions(message.acao.titulo, message.acao.mensagem)"
                          >
                            {{ message.botao || 'Saiba Mais' }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                    <v-row
                      class="justify-center mt-3"
                    >
                      <v-col
                        cols="12"
                      >
                        <span
                          class="text-h3"
                          style="color:red; height: 10px"
                          v-html="message"
                        />
                        <span
                          style="display: inline-block"
                        >
                          Fique atento ao nosso site e às nossas redes sociais para ser informado das próximas datas de agendamento.
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </base-material-card>
        </v-col>
        <alertas
          v-model="alert"
          :contents="dialogContent"
          @fecharAlerta="closeDialog"
        />
      </v-row>
    </v-container>
    <v-footer
      padless
    >
      <v-row
        justify="center"
        no-gutters
      >
        <v-btn
          v-for="link in links"
          :key="link"
          color="cyan"
          text
          rounded
          class="my-2"
          @click="goLink(link)"
        >
          <v-icon
            color="cyan"
          >
            mdi-{{ link.toLowerCase() }}
          </v-icon>
          {{ link }}
        </v-btn>
        <v-btn
          text
          rounded
          color="cyan"
          class="my-2"
          @click="toggleCookies"
        >
          <v-icon
            color="cyan"
          >
            {{ showCookies ? 'mdi-eye' : 'mdi-eye-off' }}
          </v-icon>
          Cookies
        </v-btn>
        <v-col
          class="lighten-2 text-center"
          cols="12"
        >
          <v-card
            v-if="showCookies"
            id="cookie"
            class="ma-0 pa-5"
            outlined
          >
            Usamos cookies para tornar sua experiencia a mais agradável possível.<br>Se continuar navegando neste site, você assume concordar com nossa<br>
            <span
              style="cursor: pointer"
              @click="goLink('Privacidade')"
            >
              POLÍTICA DE COOKIES E REGISTRO DE NAVEGAÇÃO
            </span>
            <br><v-btn
              class="mr-0 mt-2"
              small
              @click="toggleCookies"
            >
              OK
            </v-btn>
          </v-card>
          <b>PREFEITURA MUNICIPAL DE MOGI GUAÇU</b><br>SECRETARIA DE TECNOLOGIA E INFORMAÇÃO<br>DEPARTAMENTO DE TECNOLOGIA
          <br>
          PMMG/STI/DT AGENDAVAC OFF 1.0 / {{ new Date().getFullYear() }}
          <br>
          <img
            :src="'./uploads/nn.gif'"
            alt="DEV TEAM PMMG"
          >
        </v-col>
      </v-row>
      <lgpd
        v-model="dialog"
        @fecharAlerta="dialog = false"
      />
    </v-footer>
  </v-app>
</template>

<script>
  export default {
    components: {
      Alertas: () => import('./modules/Alertas'),
      Lgpd: () => import('./modules/Lgpd'),
    },
    data: () => ({
      alert: false,
      cards: {
        comorbidades: '#13293D',
        geral: '#1F7087',
        profissionais: '#385F73',
        avisos: '#b71c1c',
      },
      dialog: false,
      dialogContent: {},
      links: [
        'Home',
        'Facebook',
        'Instagram',
        'Youtube',
        'Privacidade',
      ],
      message: 'Agendamento não disponível.<br>',
      messages: {},
      declaracao: '',
      showCookies: true,
      tabs: 'welcome',
      timeout: false,
      timer: null,
    }),
    computed: {
      images () {
        return this.parseNotifications('imagem')
      },
    },
    async mounted () {
      await this.readNotifications()
      this.showCookies = (!localStorage.getItem('cookies') && localStorage.getItem('cookies') !== false) || false
    },
    methods: {
      async goLink (e) {
        if (e === 'Privacidade') {
          this.dialog = true
        }
        if (e === 'Facebook') {
          window.open('https://www.facebook.com/PrefeituradeMogiGuacu', '_blank')
        }
        if (e === 'Instagram') {
          window.open('https://www.instagram.com/prefeituramogiguacu/', '_blank')
        }
        if (e === 'Youtube') {
          window.open('https://www.youtube.com/channel/UCAMlQ4bD01ZEuYzODJI56Rg', '_blank')
        }
        if (e === 'Home') {
          window.open('https://mogiguacu.sp.gov.br', '_blank')
        }
        if (e === 'Termos') {
          if (this.declaracao === '') await this.termos()
          this.dialogContent = {
            button: 'ENTENDI',
            message: this.declaracao,
            persistent: true,
            title: '<b>TERMOS E DECLARAÇÕES</b>',
          }
          this.alert = true
        }
      },
      forceRerender () {
        this.$store.state.agendaChave += 1
        this.$store.commit('newtab', 'agendamento')
      },
      toggleCookies () {
        localStorage.setItem('cookies', !localStorage.getItem('cookies'))
        this.showCookies = !this.showCookies
      },
      reset: () => location.reload(),
      // NOTIFICACOES
      // Controla as açoes das notificações
      notificationActions (title, message, color = 'success', button = 'OK') {
        this.dialogContent = {
          color: color,
          button: button,
          message: message,
          title: title,
        }
        this.alert = true
      },
      // Lê o arquivo de mensagens
      async readNotifications () {
        const fileUrl = './mensagens.json'
        var notifications = await (await fetch(fileUrl)).json()
        this.messages = notifications
      },
      // Parseia as mensagens de acordo com o tipo mensagens
      parseNotifications (type) {
        return type === undefined ? false : Array.from(this.messages).filter(message => (message.ativo && message.tipo === type) && message)
      },
      // Lista dotas as mensagens e as organiza por tipos, excluindo o tipo cabecalho
      allMessages () {
        var obj = Array.from(this.messages).filter(message => (message.ativo && message.tipo !== 'cabecalho' && message.tipo !== 'imagem' && message.tipo !== 'sets') && message)
        return obj.sort((firstItem, secondItem) => (firstItem.tipo > secondItem.tipo) ? 1 : -1)
      },
      closeDialog () {
        if (this.timeout) { this.reset() }
        this.alert = false
      },
      async termos () {
        const termos = './uploads/termos.txt'
        const termosResponsavel = './uploads/termosResponsavel.txt'
        var termo = await (await fetch(termos)).text()
        var termoResponsavel = await (await fetch(termosResponsavel)).text()
        this.declaracao = `<b>Para agendar sua vacinação você concorda com os seguinte termo:</b><br><br>${termo}<br><br><b>Se o agendamento for realizado para MENOR DE IDADE você precisa ser o RESPONSÁVEL LEGAL pelo menor e também precisa concordar com o seguinte:</b><br><br>${termoResponsavel}`
      },
    },
  }
</script>

<style>
  input[type=text]{
    text-transform: uppercase !important
  }
  .v-radio > .v-label {
    color: black !important
  }
  .v-item--active {
    font-weight: 700
  }
  .text-header p:first-of-type {
    color: #22587a;
    clear: both;
    margin-bottom:-15px !important
  }
  .text-header p:last-of-type {
    color: #67b8e5;
    clear: both
  }
  .img-header {
    float: left
  }
  body, .v-application { min-width: 360px !important }
  #agendavac {
    max-width: 1200px !important;
    min-width: 340px !important
  }
  .v-card__text > h1 {
    line-height: 1em
  }
  .v-input--selection-controls .v-input__slot > .v-label {
    color: black !important
  }
  .check {
    opacity: 1 !important
  }
  .confirma {
    background-color:#2c3e50
  }
  .v-btn {
     max-width: 100% !important
  }
  @media screen and (min-width: 640px) {
    .container {
      max-width: 100%;
    }
    .text-header p {
      font-size: 2.5vw;
      margin-left: -15px;
      float: left
    }
    .text-header p:last-of-type {
      font-size: 1.52vw;
      padding-top: 5px !important
    }
    .v-application .py-5 { float: left }
    .v-application .py-5, .v-application .py-6 {
      padding-top: 0px !important;
      padding-bottom: 0px !important
    }
    .v-application p:first-of-type {
      margin-bottom: 0px
    }
    .text-header p:last-of-type {
      padding-top: 0px
    }
    #header .v-application p:first-of-type {
      font-size: 5vmin;
    }
    #header .text-header p:last-of-type {
      font-size: 3.05vmin;
    }
  }
  @media screen and (max-width: 639px) {
    .v-radio {
      align-content: right
    }
    .v-application .py-5 { float: left }
    .v-application .py-5, .v-application .py-6 {
      padding-top: 0px !important;
      padding-bottom: 0px !important
    }
    .v-application p:first-of-type {
      margin-bottom: 0px
    }
    .text-header p {
      margin-left: -10px;
      float: left
    }
    #header .v-application p:first-of-type {
      font-size: 7vmin
    }
    #header .text-header p {
      font-size: 4.3vw
    }
    .text-header p:last-of-type {
      color: #67b8e5;
      clear: both;
      padding-top: 7px
    }
  }
  @media screen
  and (max-height: 639px)
  and (orientation: landscape) {
    .text-header p:first-of-type {
      font-size: 2.5vw !important;
      margin-bottom:-15px !important
    }
    .text-header p:last-of-type {
      font-size: 1.52vw !important;
      padding-top: 5px !important
    }
  }
  .select-buttons > button {
    background-color: #fff !important;
    color: #000 !important;
    font-size: 14px !important
  }
  .select-buttons > button:hover,
  .select-buttons > .v-btn--active {
    background-color: #518eb1 !important;
    color: white !important
  }
  .select-buttons > button:hover { opacity: .8 }
  @media screen and (max-width:640px) {
    .v-btn-toggle {
      border-radius: 0 !important;
      display: block !important;
      max-width: 100% !important
    }
    .select-buttons > button {
      border: 1px solid rgba(0, 0, 0, .12) !important
    }
  }
  .theme--light.v-btn.v-btn--disabled {
    color: #777 !important;
  }
  .disabled { background-color: #e2e2e2 !important }
  .container { padding-bottom: 0 }
  #cookie {
    max-width: 600px;
    position: fixed;
    bottom: 10px;
    display: grid;
    left: 10px;
    background: rgba(0,0,0,.8);
    color: #fff;
    z-index: 99999999
  }
  .theme--light.v-footer {
    background-color: #fff !important
  }
 .theme--light.v-footer img {
    opacity: .1
  }
  .theme--light.v-footer img:hover {
    opacity: .5
  }
  .select-buttons > button {
    color: #777777 !important;
    font-size: 18px !important;
  }
  .select-buttons > button svg {
    fill: #777777
  }
  .select-buttons > button:hover svg, .select-buttons > button.v-item--active svg {
    fill: #FFFFFF
  }
  #btn_idade {
    background-color: #A5D6A7 !important
  }
  #btn_idade.v-item--active {
    background-color: #2E7D32 !important;
    color: #FFFFFF !important;
    opacity: 1
  }
  #btn_idade.v-item--active.v-btn--disabled {
    color: #FFFFFF !important
  }
  #btn_profissao {
    background-color: #EF9A9A !important
  }
  #btn_profissao.v-item--active {
    background-color: #F44336 !important;
    opacity: 1
  }
  #btn_comorbidade {
    background-color: #90CAF9 !important
  }
  #btn_comorbidade.v-item--active {
    background-color: #1565C0 !important;
    opacity: 1
  }
  .v-btn__content {
    display: block !important
  }
  .v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
    height: auto !important
  }
  .row-pointer {
    cursor: pointer
  }
  </style>
